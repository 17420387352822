:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}
.animated {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
            animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
.animated.infinite {
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}
.animated.delay-100ms {
    -webkit-animation-delay: 100ms;
            animation-delay: 100ms;
}
.animated.delay-200ms {
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
}
.animated.delay-300ms {
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
}
.animated.delay-400ms {
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
}
.animated.delay-500ms {
    -webkit-animation-delay: 500ms;
            animation-delay: 500ms;
}
.animated.delay-600ms {
    -webkit-animation-delay: 600ms;
            animation-delay: 600ms;
}
.animated.delay-700ms {
    -webkit-animation-delay: 700ms;
            animation-delay: 700ms;
}
.animated.delay-800ms {
    -webkit-animation-delay: 800ms;
            animation-delay: 800ms;
}
.animated.delay-900ms {
    -webkit-animation-delay: 900ms;
            animation-delay: 900ms;
}
.animated.delay-1000ms {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-delay: var(--animate-delay);
            animation-delay: var(--animate-delay);
}
.animated.delay-2000ms {
    -webkit-animation-delay: calc(1s * 2);
            animation-delay: calc(1s * 2);
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
            animation-delay: calc(var(--animate-delay) * 2);
}
.animated.delay-3000ms {
    -webkit-animation-delay: calc(1s * 3);
            animation-delay: calc(1s * 3);
    -webkit-animation-delay: calc(var(--animate-delay) * 3);
            animation-delay: calc(var(--animate-delay) * 3);
}
.animated.delay-4000ms {
    -webkit-animation-delay: calc(1s * 4);
            animation-delay: calc(1s * 4);
    -webkit-animation-delay: calc(var(--animate-delay) * 4);
            animation-delay: calc(var(--animate-delay) * 4);
}
.animated.delay-5000ms {
    -webkit-animation-delay: calc(1s * 5);
            animation-delay: calc(1s * 5);
    -webkit-animation-delay: calc(var(--animate-delay) * 5);
            animation-delay: calc(var(--animate-delay) * 5);
}
.animated.fastest {
    -webkit-animation-duration: calc(1s / 4);
            animation-duration: calc(1s / 4);
    -webkit-animation-duration: calc(var(--animate-duration) / 4);
            animation-duration: calc(var(--animate-duration) / 4);
}
.animated.faster {
    -webkit-animation-duration: calc(1s / 2);
            animation-duration: calc(1s / 2);
    -webkit-animation-duration: calc(var(--animate-duration) / 2);
            animation-duration: calc(var(--animate-duration) / 2);
}
.animated.fast {
    -webkit-animation-duration: calc(1s * 0.75);
            animation-duration: calc(1s * 0.75);
    -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
            animation-duration: calc(var(--animate-duration) * 0.75);
}
.animated.slow {
    -webkit-animation-duration: calc(1s * 1.25);
            animation-duration: calc(1s * 1.25);
    -webkit-animation-duration: calc(var(--animate-duration) * 1.25);
            animation-duration: calc(var(--animate-duration) * 1.25);
}
.animated.slower {
    -webkit-animation-duration: calc(1s / 0.5);
            animation-duration: calc(1s / 0.5);
    -webkit-animation-duration: calc(var(--animate-duration) / 0.5);
            animation-duration: calc(var(--animate-duration) / 0.5);
}
.animated.slowest {
    -webkit-animation-duration: calc(1s / 0.25);
            animation-duration: calc(1s / 0.25);
    -webkit-animation-duration: calc(var(--animate-duration) / 0.25);
            animation-duration: calc(var(--animate-duration) / 0.25);
}
@media print, (prefers-reduced-motion: reduce) {
    .animated {
        -webkit-animation-duration:  1ms!important;
                animation-duration:  1ms!important;
        -webkit-transition-duration: 1ms!important;
                transition-duration: 1ms!important;
        -webkit-animation-iteration-count: 1!important;
                animation-iteration-count: 1!important;
    }

    .animated[class*='Out'] {
        opacity: 0;
    }
}
/* Attention seekers  */
@-webkit-keyframes anim_flash {
    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}
@keyframes anim_flash {
    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}
.flash {
    -webkit-animation-name: anim_flash;
            animation-name: anim_flash;
}
/* pulse */
@-webkit-keyframes anim_pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
                transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}
@keyframes anim_pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
                transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}
.pulse {
    -webkit-animation-name: anim_pulse;
            animation-name: anim_pulse;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}
/* In Yo Face */
@-webkit-keyframes anim_gettinInYoFace {
    from {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(100);
                transform: scale(100);
    }
}
@keyframes anim_gettinInYoFace {
    from {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(100);
                transform: scale(100);
    }
}
.gettinInYoFace {
    -webkit-animation-name: anim_gettinInYoFace;
            animation-name: anim_gettinInYoFace;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}
/* Nope */
@-webkit-keyframes anim_nope {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    6.5% {
        -webkit-transform: translateX(-7px) rotateY(-10deg);
                transform: translateX(-7px) rotateY(-10deg);
    }

    18.5% {
        -webkit-transform: translateX(6px) rotateY(8deg);
                transform: translateX(6px) rotateY(8deg);
    }

    31.5% {
        -webkit-transform: translateX(-4px) rotateY(-6deg);
                transform: translateX(-4px) rotateY(-6deg);
    }

    43.5% {
        -webkit-transform: translateX(3px) rotateY(4deg);
                transform: translateX(3px) rotateY(4deg);
    }

    50% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes anim_nope {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    6.5% {
        -webkit-transform: translateX(-7px) rotateY(-10deg);
                transform: translateX(-7px) rotateY(-10deg);
    }

    18.5% {
        -webkit-transform: translateX(6px) rotateY(8deg);
                transform: translateX(6px) rotateY(8deg);
    }

    31.5% {
        -webkit-transform: translateX(-4px) rotateY(-6deg);
                transform: translateX(-4px) rotateY(-6deg);
    }

    43.5% {
        -webkit-transform: translateX(3px) rotateY(4deg);
                transform: translateX(3px) rotateY(4deg);
    }

    50% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
.nope {
    -webkit-animation-name: anim_nope;
            animation-name: anim_nope;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}
/* Getting lifted */
@-webkit-keyframes anim_gettinLifted {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 500px, 0);
                transform: translate3d(0, 500px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}
@keyframes anim_gettinLifted {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 500px, 0);
                transform: translate3d(0, 500px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}
.gettinLifted {
    animation-name: anim_gettinLifted;
    animation-duration: 3s;
    animation-timing-function: ease-in;
}
/* Back entrances */
@-webkit-keyframes anim_backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
                transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes anim_backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
                transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
.backInDown {
    -webkit-animation-name: anim_backInDown;
            animation-name: anim_backInDown;
}
@-webkit-keyframes anim_backInLeft {
    0% {
        -webkit-transform: translateX(-2000px) scale(0.7);
                transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes anim_backInLeft {
    0% {
        -webkit-transform: translateX(-2000px) scale(0.7);
                transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
.backInLeft {
    -webkit-animation-name: anim_backInLeft;
            animation-name: anim_backInLeft;
}
@-webkit-keyframes anim_backInRight {
    0% {
        -webkit-transform: translateX(2000px) scale(0.7);
                transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes anim_backInRight {
    0% {
        -webkit-transform: translateX(2000px) scale(0.7);
                transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
.backInRight {
    -webkit-animation-name: anim_backInRight;
            animation-name: anim_backInRight;
}
@-webkit-keyframes anim_backInUp {
    0% {
        -webkit-transform: translateY(1200px) scale(0.7);
                transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes anim_backInUp {
    0% {
        -webkit-transform: translateY(1200px) scale(0.7);
                transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
.backInUp {
    -webkit-animation-name: anim_backInUp;
            animation-name: anim_backInUp;
}
/* Back exits */
@-webkit-keyframes anim_backOutDown {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(700px) scale(0.7);
                transform: translateY(700px) scale(0.7);
        opacity: 0.7;
    }
}
@keyframes anim_backOutDown {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(700px) scale(0.7);
                transform: translateY(700px) scale(0.7);
        opacity: 0.7;
    }
}
.backOutDown {
    -webkit-animation-name: anim_backOutDown;
            animation-name: anim_backOutDown;
}
@-webkit-keyframes anim_backOutLeft {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(-2000px) scale(0.7);
                transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }
}
@keyframes anim_backOutLeft {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(-2000px) scale(0.7);
                transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }
}
.backOutLeft {
    -webkit-animation-name: anim_backOutLeft;
            animation-name: anim_backOutLeft;
}
@-webkit-keyframes anim_backOutRight {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(2000px) scale(0.7);
                transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }
}
@keyframes anim_backOutRight {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
                transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(2000px) scale(0.7);
                transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }
}
.backOutRight {
    -webkit-animation-name: anim_backOutRight;
            animation-name: anim_backOutRight;
}
@-webkit-keyframes anim_backOutUp {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(-700px) scale(0.7);
                transform: translateY(-700px) scale(0.7);
        opacity: 0.7;
    }
}
@keyframes anim_backOutUp {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
                transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(-700px) scale(0.7);
                transform: translateY(-700px) scale(0.7);
        opacity: 0.7;
    }
}
.backOutUp {
    -webkit-animation-name: anim_backOutUp;
            animation-name: anim_backOutUp;
}
/* Fading entrances  */
@-webkit-keyframes anim_fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes anim_fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.fadeIn {
    -webkit-animation-name: anim_fadeIn;
            animation-name: anim_fadeIn;
}
@-webkit-keyframes anim_fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInDown {
    -webkit-animation-name: anim_fadeInDown;
            animation-name: anim_fadeInDown;
}
@-webkit-keyframes anim_fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInDownBig {
    -webkit-animation-name: anim_fadeInDownBig;
            animation-name: anim_fadeInDownBig;
}
@-webkit-keyframes anim_fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInLeft {
    -webkit-animation-name: anim_fadeInLeft;
            animation-name: anim_fadeInLeft;
}
@-webkit-keyframes anim_fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInLeftBig {
    -webkit-animation-name: anim_fadeInLeftBig;
            animation-name: anim_fadeInLeftBig;
}
@-webkit-keyframes anim_fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInRight {
    -webkit-animation-name: anim_fadeInRight;
            animation-name: anim_fadeInRight;
}
@-webkit-keyframes anim_fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInRightBig {
    -webkit-animation-name: anim_fadeInRightBig;
            animation-name: anim_fadeInRightBig;
}
@-webkit-keyframes anim_fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInUp {
    -webkit-animation-name: anim_fadeInUp;
            animation-name: anim_fadeInUp;
}
@-webkit-keyframes anim_fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInUpBig {
    -webkit-animation-name: anim_fadeInUpBig;
            animation-name: anim_fadeInUpBig;
}
@-webkit-keyframes anim_fadeInTopLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
                transform: translate3d(-100%, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInTopLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
                transform: translate3d(-100%, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInTopLeft {
    -webkit-animation-name: anim_fadeInTopLeft;
            animation-name: anim_fadeInTopLeft;
}
@-webkit-keyframes anim_fadeInTopRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
                transform: translate3d(100%, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInTopRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
                transform: translate3d(100%, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInTopRight {
    -webkit-animation-name: anim_fadeInTopRight;
            animation-name: anim_fadeInTopRight;
}
@-webkit-keyframes anim_fadeInBottomLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
                transform: translate3d(-100%, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInBottomLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
                transform: translate3d(-100%, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInBottomLeft {
    -webkit-animation-name: anim_fadeInBottomLeft;
            animation-name: anim_fadeInBottomLeft;
}
@-webkit-keyframes anim_fadeInBottomRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
                transform: translate3d(100%, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_fadeInBottomRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
                transform: translate3d(100%, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.fadeInBottomRight {
    -webkit-animation-name: anim_fadeInBottomRight;
            animation-name: anim_fadeInBottomRight;
}
/* Fading exits */
@-webkit-keyframes anim_fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@keyframes anim_fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.fadeOut {
    -webkit-animation-name: anim_fadeOut;
            animation-name: anim_fadeOut;
}
@-webkit-keyframes anim_fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }
}
@keyframes anim_fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }
}
.fadeOutDown {
    -webkit-animation-name: anim_fadeOutDown;
            animation-name: anim_fadeOutDown;
}
@-webkit-keyframes anim_fadeOutDownBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }
}
@keyframes anim_fadeOutDownBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }
}
.fadeOutDownBig {
    -webkit-animation-name: anim_fadeOutDownBig;
            animation-name: anim_fadeOutDownBig;
}
@-webkit-keyframes anim_fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}
@keyframes anim_fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}
.fadeOutLeft {
    -webkit-animation-name: anim_fadeOutLeft;
            animation-name: anim_fadeOutLeft;
}
@-webkit-keyframes anim_fadeOutLeftBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}
@keyframes anim_fadeOutLeftBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}
.fadeOutLeftBig {
    -webkit-animation-name: anim_fadeOutLeftBig;
            animation-name: anim_fadeOutLeftBig;
}
@-webkit-keyframes anim_fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }
}
@keyframes anim_fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }
}
.fadeOutRight {
    -webkit-animation-name: anim_fadeOutRight;
            animation-name: anim_fadeOutRight;
}
@-webkit-keyframes anim_fadeOutRightBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }
}
@keyframes anim_fadeOutRightBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }
}
.fadeOutRightBig {
    -webkit-animation-name: anim_fadeOutRightBig;
            animation-name: anim_fadeOutRightBig;
}
@-webkit-keyframes anim_fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
}
@keyframes anim_fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
}
.fadeOutUp {
    -webkit-animation-name: anim_fadeOutUp;
            animation-name: anim_fadeOutUp;
}
@-webkit-keyframes anim_fadeOutUpBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }
}
@keyframes anim_fadeOutUpBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }
}
.fadeOutUpBig {
    -webkit-animation-name: anim_fadeOutUpBig;
            animation-name: anim_fadeOutUpBig;
}
@-webkit-keyframes anim_fadeOutTopLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
                transform: translate3d(-100%, -100%, 0);
    }
}
@keyframes anim_fadeOutTopLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
                transform: translate3d(-100%, -100%, 0);
    }
}
.fadeOutTopLeft {
    -webkit-animation-name: anim_fadeOutTopLeft;
            animation-name: anim_fadeOutTopLeft;
}
@-webkit-keyframes anim_fadeOutTopRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
                transform: translate3d(100%, -100%, 0);
    }
}
@keyframes anim_fadeOutTopRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
                transform: translate3d(100%, -100%, 0);
    }
}
.fadeOutTopRight {
    -webkit-animation-name: anim_fadeOutTopRight;
            animation-name: anim_fadeOutTopRight;
}
@-webkit-keyframes anim_fadeOutBottomRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
                transform: translate3d(100%, 100%, 0);
    }
}
@keyframes anim_fadeOutBottomRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
                transform: translate3d(100%, 100%, 0);
    }
}
.fadeOutBottomRight {
    -webkit-animation-name: anim_fadeOutBottomRight;
            animation-name: anim_fadeOutBottomRight;
}
@-webkit-keyframes anim_fadeOutBottomLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
                transform: translate3d(-100%, 100%, 0);
    }
}
@keyframes anim_fadeOutBottomLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
                transform: translate3d(-100%, 100%, 0);
    }
}
.fadeOutBottomLeft {
    -webkit-animation-name: anim_fadeOutBottomLeft;
            animation-name: anim_fadeOutBottomLeft;
}
/* Specials */
@-webkit-keyframes anim_hinge {
    0% {
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
                transform: rotate3d(0, 0, 1, 80deg);
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
                transform: rotate3d(0, 0, 1, 60deg);
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
                transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}
@keyframes anim_hinge {
    0% {
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
                transform: rotate3d(0, 0, 1, 80deg);
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
                transform: rotate3d(0, 0, 1, 60deg);
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
                transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}
.hinge {
    -webkit-animation-duration: calc(1s * 2);
            animation-duration: calc(1s * 2);
    -webkit-animation-duration: calc(var(--animate-duration) * 2);
            animation-duration: calc(var(--animate-duration) * 2);
    -webkit-animation-name: anim_hinge;
            animation-name: anim_hinge;
    -webkit-transform-origin: top left;
    transform-origin: top left;
}
@-webkit-keyframes anim_jackInTheBox {
    from {
        opacity: 0;
        -webkit-transform: scale(0.1) rotate(30deg);
                transform: scale(0.1) rotate(30deg);
        -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
    }

    50% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }

    70% {
        -webkit-transform: rotate(3deg);
                transform: rotate(3deg);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes anim_jackInTheBox {
    from {
        opacity: 0;
        -webkit-transform: scale(0.1) rotate(30deg);
                transform: scale(0.1) rotate(30deg);
        -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
    }

    50% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }

    70% {
        -webkit-transform: rotate(3deg);
                transform: rotate(3deg);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
.jackInTheBox {
    -webkit-animation-name: anim_jackInTheBox;
            animation-name: anim_jackInTheBox;
}
/* Roll in */
@-webkit-keyframes anim_rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
                transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
                transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.rollIn {
    -webkit-animation-name: anim_rollIn;
            animation-name: anim_rollIn;
}
/* Roll out */
@-webkit-keyframes anim_rollOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
                transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
}
@keyframes anim_rollOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
                transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
}
.rollOut {
    -webkit-animation-name: anim_rollOut;
            animation-name: anim_rollOut;
}
/* Zooming entrances */
@-webkit-keyframes anim_zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}
@keyframes anim_zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}
.zoomIn {
    -webkit-animation-name: anim_zoomIn;
            animation-name: anim_zoomIn;
}
@-webkit-keyframes anim_zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
@keyframes anim_zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInDown {
    -webkit-animation-name: anim_zoomInDown;
            animation-name: anim_zoomInDown;
}
@-webkit-keyframes anim_zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
@keyframes anim_zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInLeft {
    -webkit-animation-name: anim_zoomInLeft;
            animation-name: anim_zoomInLeft;
}
@-webkit-keyframes anim_zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
@keyframes anim_zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInRight {
    -webkit-animation-name: anim_zoomInRight;
            animation-name: anim_zoomInRight;
}
@-webkit-keyframes anim_zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
@keyframes anim_zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInUp {
    -webkit-animation-name: anim_zoomInUp;
            animation-name: anim_zoomInUp;
}
/* Zooming exits */
@-webkit-keyframes anim_zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}
@keyframes anim_zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}
.zoomOut {
    -webkit-animation-name: anim_zoomOut;
            animation-name: anim_zoomOut;
}
@-webkit-keyframes anim_zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
@keyframes anim_zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomOutDown {
    -webkit-animation-name: anim_zoomOutDown;
            animation-name: anim_zoomOutDown;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}
@-webkit-keyframes anim_zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
                transform: scale(0.1) translate3d(-2000px, 0, 0);
    }
}
@keyframes anim_zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
                transform: scale(0.1) translate3d(-2000px, 0, 0);
    }
}
.zoomOutLeft {
    -webkit-animation-name: anim_zoomOutLeft;
            animation-name: anim_zoomOutLeft;
    -webkit-transform-origin: left center;
    transform-origin: left center;
}
@-webkit-keyframes anim_zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
                transform: scale(0.1) translate3d(2000px, 0, 0);
    }
}
@keyframes anim_zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
                transform: scale(0.1) translate3d(2000px, 0, 0);
    }
}
.zoomOutRight {
    -webkit-animation-name: anim_zoomOutRight;
            animation-name: anim_zoomOutRight;
    -webkit-transform-origin: right center;
    transform-origin: right center;
}
@-webkit-keyframes anim_zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
@keyframes anim_zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomOutUp {
    -webkit-animation-name: anim_zoomOutUp;
            animation-name: anim_zoomOutUp;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}
/* Sliding entrances */
@-webkit-keyframes anim_slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.slideInDown {
    -webkit-animation-name: anim_slideInDown;
            animation-name: anim_slideInDown;
}
@-webkit-keyframes anim_slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.slideInLeft {
    -webkit-animation-name: anim_slideInLeft;
            animation-name: anim_slideInLeft;
}
@-webkit-keyframes anim_slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.slideInRight {
    -webkit-animation-name: anim_slideInRight;
            animation-name: anim_slideInRight;
}
@-webkit-keyframes anim_slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes anim_slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
.slideInUp {
    -webkit-animation-name: anim_slideInUp;
            animation-name: anim_slideInUp;
}
/* Sliding exits */
@-webkit-keyframes anim_slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }
}
@keyframes anim_slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }
}
.slideOutDown {
    -webkit-animation-name: anim_slideOutDown;
            animation-name: anim_slideOutDown;
}
@-webkit-keyframes anim_slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}
@keyframes anim_slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}
.slideOutLeft {
    -webkit-animation-name: anim_slideOutLeft;
            animation-name: anim_slideOutLeft;
}
@-webkit-keyframes anim_slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }
}
@keyframes anim_slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }
}
.slideOutRight {
    -webkit-animation-name: anim_slideOutRight;
            animation-name: anim_slideOutRight;
}
@-webkit-keyframes anim_slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
}
@keyframes anim_slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
}
.slideOutUp {
    -webkit-animation-name: anim_slideOutUp;
            animation-name: anim_slideOutUp;
}

/*  end basic */
